<template>
    <div>
        <div class="vx-row" v-if="loading || (!this.$route.meta.ignore_entity_check && !entity.id)">
            <div class="vx-col w-full">
                <vx-card class="text-center" v-if="loading">
                    <h1>Loading.. Please Wait..</h1>
                </vx-card>
            </div>
        </div>
        <template v-else>
            <router-view :entity.sync="entity" :key="forceRender"></router-view>
        </template>
    </div>
</template>

<script>
import { faEdit } from '@fortawesome/free-solid-svg-icons'
export default {
    data() {
        return {
            entity_id: this.$route.params.id,
            entity: {
                entity_type: null
            },
            loading: false,
            forceRender: 0,
        }
    },
    computed: {
        editIcon: () => faEdit
    },
    mounted(){
        this.init()
    },
    watch: {
        $route(newV, oldV) {
            if(newV.params.id == oldV.params.id) return

            this.entity_id = this.$route.params.id
            this.init()
        },
        'entity.entity_type'(newV, oldV) {
            if(newV != oldV)
                this.entity.entity_type_label = this.getEntityType(newV)
        },
        entity: {
            handler(newV) {
                this.forceRender += 1
            },
            deep: true
        }
    },
    methods: {
        init() {
            if(!this.entity_id) {
                return this.$router.push("/")
            }
            this.load_entity()
        },
        clearEntityState() {
            this.$store.dispatch('organisation/updateActiveEntity', null)
        },
        load_entity(redirect=true) {
            this.clearEntityState()
            this.loading = true
            this.$vs.loading()

            this.$api(this, 'GET', this.$store.state.apiendpoints.data_entity, {entity_id: this.entity_id}).then(
                (res) => {

                    this.loading = false
                    this.$vs.loading.close()

                    if(!res.active) {
                        this.$vs.notify({
                            time: 4000,
                            title: 'Error',
                            text: "Entity is disabled. Please activate it and try again.",
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })

                        if(this.$store.state.organisation.entity && this.$store.state.organisation.entity.id == res.id) {
                            this.$store.dispatch('organisation/updateActiveEntity', null)
                        }
                        return this.$router.push("/")
                    }

                    this.entity = res
                    this.entity.entity_type_label = this.getEntityType(this.entity.entity_type)
                    this.entity.refresh_entity = this.init

                    this.$store.dispatch('organisation/updateActiveEntity', this.entity)
                    this.$store.dispatch('updateActiveMenuSection', 'entity')

                    this.loading = false
                },
                (err) => {
                    this.loading = false
                    this.$vs.loading.close()

                    this.$vs.notify({
                        time: 2500,
                        title: 'Error',
                        text: "Unable to load Entity. Please try again later.",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                    if(redirect)
                        this.$router.push("/")
                }
            )
        },
        getEntityType(entity_type) {
            let entity_Types = {
              'company': "Company",
              'llp': "LLP",
              'partnership': "Partnership",
              'individual': "Individual",
              'huf': "HUF",
              'trust': "Trust",
              'society': "Society",
              'aop': "AOP",
              'boi': "BOI",
            }

            if(!entity_type)
                return "Unknown"
            else
                return entity_Types[entity_type] || "Unknown"
        },
    }
}
</script>
